import { OsTable } from '@/components';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { OsTableOption, OsTableColumn } from '@/components/os-table/os-table';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { DialogMixin } from '@/mixins/dialog';
import {
  NumberAssignmentTypeEnum,
  OrderTypeEnum,
  SelectAssignmentTypeEnum,
  StringAssignmentTypeEnum
} from '@/resource/enum';
import { CompleteManuscriptWorkContentInfo, FieldOption, FieldOptionList } from '@/resource/model';
import { messageError, messageErrors, translation } from '@/utils';

import { Message, Table } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { BatchSetupData } from '@/views/dialogs';
import { preperssStatisticsService, PrepressCraftsService } from '@/api';

import { ApiError } from '@/api/axios';
import { cloneDeep } from 'lodash-es';
@Component({
  name: 'EditWorkContent',
  components: {
    BatchSetupData
  }
})
export default class EditWorkContent extends mixins(DialogMixin, CustomColumnMixin) {
  /**
   * 要改稿的数据
   */
  @Prop({
    required: false,
    type: Array,
    default: () => {
      return [];
    }
  })
  public editList!: Array<CompleteManuscriptWorkContentInfo>;

  public dialogVisible = false;

  public tableOption: OsTableOption<CompleteManuscriptWorkContentInfo> = {
    height: '400px',
    loading: false,
    data: [],
    fit: true
  };
  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'batchSetupData.title',
      operationType: 'batchUpdate',
      disabled: true,
      handleClick: (): void => {
        this.openDialog();
      }
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'batchSetupData.remove',
      operationType: 'delete',
      disabled: true,
      handleClick: (): void => {
        this.batchDelete();
      }
    }
  ];
  /**
   * 默认的表格列配置
   */
  public defaultColumnOptions: Array<OsTableColumn<CompleteManuscriptWorkContentInfo>> = [
    {
      type: 'selection',
      prop: 'key',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'itemCode',
      label: 'prepress.itemCode',
      minWidth: '200px',
      sortable: 'custom',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'modifyCount',
      label: 'prepress.round',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'itemType',
      label: 'prepressStatistics.orderType',
      minWidth: '100px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        const itemType = (row as CompleteManuscriptWorkContentInfo).itemType;
        return translation(`projectItemType.${OrderTypeEnum[itemType]}`);
      }
    },
    {
      prop: 'platformProductName',
      label: 'prepress.platformProduct',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'prepressCraftsName',
      label: 'projectProduct.prepressCrafts',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressSubmitCount',
      label: 'projectProduct.prepressSubmitCount',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'factor',
      label: 'prepressCrafts.factor',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'updateReason',
      label: 'prepressStatistics.updateReason',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'workload',
      label: 'prepressStatistics.workload',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: object): number => {
        return (
          (row as CompleteManuscriptWorkContentInfo).factor *
          (row as CompleteManuscriptWorkContentInfo).prepressSubmitCount
        );
      }
    },
    {
      prop: 'visibleHeight',
      label: 'prepress.visibleSize',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: Object): string => {
        return `${(row as CompleteManuscriptWorkContentInfo).visibleWidth} × ${
          (row as CompleteManuscriptWorkContentInfo).visibleHeight
        }`;
      }
    },
    {
      prop: 'finishHeight',
      label: 'prepress.finishSize',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: Object): string => {
        return `${(row as CompleteManuscriptWorkContentInfo).finishWidth} × ${
          (row as CompleteManuscriptWorkContentInfo).finishHeight
        }`;
      }
    },
    {
      prop: 'prepressRemark',
      label: 'prepress.prepressRemark',
      minWidth: '180px',
      showOverflowTooltip: true
    }
  ];

  public prepressCraftsOptions: Array<any> = [];
  public selectedRows: Array<CompleteManuscriptWorkContentInfo> = [];
  /**
   * 字段批量修改配置项
   */
  public fieldOptions: Array<FieldOptionList<CompleteManuscriptWorkContentInfo>> = [
    {
      fieldName: 'prepressSubmitCount',
      fieldLabel: '交稿数量',
      disabled: false,
      fieldType: 'number'
    },
    {
      fieldName: 'factor',
      fieldLabel: '难度系数',
      disabled: false,
      fieldType: 'number'
    },
    {
      fieldName: 'updateReason',
      fieldLabel: '修改原因',
      disabled: false,
      fieldType: 'string'
    },
    {
      fieldName: 'prepressCraftsIds',
      fieldLabel: '印前工艺',
      disabled: false,
      fieldType: 'select'
    }
  ];
  public created(): void {}

  public async dialogOpen(): Promise<void> {
    this.tableOption.data = [];
    await this.getPrepressCraftsOptions();
    const arr = this.editList;
    arr.forEach((x, index) => {
      x.prepressCraftsList = [];
      x.prepressCraftsIds = [];
      x.showEdit = index === 0;
      const ids = x.prepressCraftsId.split(',');
      const nameList = x.prepressCraftsName.split(',');
      ids.forEach((id, index) => {
        x.prepressCraftsIds.push(Number(id));
        x.prepressCraftsList!.push({ id: Number(id), label: nameList[index], factor: x.factor });
      });
      this.getSelectList(x);
    });
    this.tableOption.data.push(...arr);
  }

  public dialogClosed(): void {
    this.clearSelection();
  }

  public openDialog(): void {
    if (this.selectedRows.length === 0) {
      Message.warning(translation('batchSetupData.selectDataTip'));
      return;
    }
    // 打开字段修改
    this.dialogVisible = true;
  }

  public batchDelete(): void {
    if (this.selectedRows.length === 0) {
      Message.warning(translation('batchSetupData.selectDataTip'));
      return;
    }
    if (this.selectedRows.length === this.tableOption.data.length) {
      Message.warning(translation('common.keepDataTip'));
      return;
    }
    this.tableOption.data = this.tableOption.data.filter(data => !this.selectedRows.includes(data));
    this.clearSelection();
  }
  public handlePrepressCraftsList(
    value: Array<{ id: number; label: string; factor: number }>
  ): Array<{ id: number; label: string; factor: number }> {
    return value;
  }

  public getSelectList(rowdata: CompleteManuscriptWorkContentInfo): void {
    const list = [...rowdata.prepressCraftsList, ...this.prepressCraftsOptions];
    const uniqueItems = Array.from(
      list
        .reduce((map, item) => {
          const { id } = item;
          map.set(id, map.has(id) ? map.get(id) : item);
          return map;
        }, new Map())
        .values()
    );
    rowdata.prepressCraftsList.length = 0;
    rowdata.prepressCraftsList.push(...(uniqueItems as Array<{ id: number; label: string; factor: number }>));
  }

  public async getPrepressCraftsOptions(): Promise<void> {
    try {
      const res = await PrepressCraftsService.listForComponents({}, { currentPage: 1, showCount: 1000 });
      res.data.forEach(x => {
        this.prepressCraftsOptions.push({ id: x.id, label: x.name, factor: x.factor });
      });
      console.log('印前工艺', this.prepressCraftsOptions);
    } catch (error) {
      messageError(error);
    }
  }
  /**
   * 表格勾选事件
   */
  public handleSelectionChange(selectedData: Array<CompleteManuscriptWorkContentInfo>): void {
    this.selectedRows = [...selectedData];
  }
  /**
   * 表格勾选事件
   */
  public handleRowClick(rowData: CompleteManuscriptWorkContentInfo): void {
    rowData.showEdit = true;
    this.tableOption.data
      .filter(x => rowData.key !== x.key)
      .forEach(cur => {
        cur.showEdit = false;
      });
  }

  public numberChange(row: CompleteManuscriptWorkContentInfo, fieldName: string): void {
    const entries = new Map(Object.entries(row));
    if (entries.get('fieldName')) {
      entries.set(fieldName, 0);
    }
    Object.assign(row, Object.fromEntries(entries));
  }

  /**
   * 印前工艺变更
   * @param row 行数据
   * @param cover true 难度系数由印前工艺绝决定、false不做变更
   */
  public handleChange(row: CompleteManuscriptWorkContentInfo, cover: boolean = true): void {
    const prepressCraftsList = row.prepressCraftsList.filter(x => !!row.prepressCraftsIds.includes(x.id));
    row.prepressCraftsId = prepressCraftsList.map(x => x.id).join(',');
    row.prepressCraftsName = prepressCraftsList.map(x => x.label).join(',');
    const itemFactor = Math.max(...prepressCraftsList.map(x => x.factor));
    if (cover) {
      row.factor = itemFactor;
    }
  }
  /**
   * 批量修改赋值
   */
  public setData(field: Array<FieldOption>): void {
    this.selectedRows.forEach(item => {
      const entries = new Map(Object.entries(item));
      field.forEach(cur => {
        if (cur.fieldType === 'string') {
          switch (cur.assignmentType) {
            case StringAssignmentTypeEnum.update:
              entries.set(cur.fieldName, cur.fieldValue);
              break;
            case StringAssignmentTypeEnum.increase:
              entries.set(cur.fieldName, `${entries.get(cur.fieldName) || ''}${cur.fieldValue}`);
              break;
            default:
              break;
          }
        } else if (cur.fieldType === 'number') {
          switch (cur.assignmentType) {
            case NumberAssignmentTypeEnum.equal:
              entries.set(cur.fieldName, cur.fieldValue);
              break;
            case NumberAssignmentTypeEnum.add:
              entries.set(cur.fieldName, entries.get(cur.fieldName) + cur.fieldValue);
              break;
            case NumberAssignmentTypeEnum.sub:
              entries.set(cur.fieldName, entries.get(cur.fieldName) - cur.fieldValue);
              break;
            default:
              break;
          }
        } else if (cur.fieldType === 'select') {
          switch (cur.assignmentType) {
            case SelectAssignmentTypeEnum.update:
              entries.set(cur.fieldName, cur.fieldValueItem);
              break;
            default:
              break;
          }
        }
        Object.assign(item, Object.fromEntries(entries));
      });
      this.handleChange(item, false);
    });
    this.clearSelection();
    this.dialogVisible = false;
  }

  /**
   * 数据校验
   * @returns
   */
  public validate(): boolean {
    if (this.tableOption.data?.length === 0) {
      Message.warning(`${translation('没有可提交的数据')}`);
      return false;
    }
    const errors: Array<ApiError> = [];
    this.tableOption.data?.forEach(item => {
      let message = '';
      const { prepressSubmitCount, prepressCraftsIds, factor, updateReason } = item as Partial<
        CompleteManuscriptWorkContentInfo
      >;
      if (prepressCraftsIds!.length === 0) {
        // 点位名称超出长度
        message = `[印前工艺：未选择]`;
      }
      if (!factor || factor === 0) {
        message = message + `[难度系数：未填写]`;
      }

      if (!updateReason) {
        message = message + `[修改原因：未填写]`;
      } else if (updateReason.length > 200) {
        message = message + `[修改原因：超出长度范围]`;
      }

      if (!prepressSubmitCount || prepressSubmitCount <= 0) {
        message = message + `[交稿数量：要求大于等于1]`;
      }

      if (message) {
        errors.push({
          message: `【${item.itemCode}】: ${message} `,
          level: 1
        });
      }
    });
    if (errors.length > 0) {
      messageErrors(errors);
      return true;
    }
    return false;
  }

  public async onSubmit(): Promise<void> {
    this.setLoading(true);
    // 校验数据
    if (this.validate()) {
      this.setLoading(false);
      return;
    }
    const workContent = cloneDeep(this.tableOption.data);
    try {
      this.setLoading(true);
      await preperssStatisticsService.put(workContent);
      Message.success(translation('operationRes.editSuccess'));
      this.$emit('edit-success');
      this.closeDialog();
    } catch (error) {
      if (error) messageErrors(error);
    } finally {
      this.setLoading(false);
    }
    this.syncedVisible = false;
  }

  /**
   * 清空选中状态
   */
  public clearSelection(): void {
    ((this.$refs.editWorkContentTable as OsTable).tableRef as Table).clearSelection();
  }
  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<CompleteManuscriptWorkContentInfo>): void {
    const dynamicBtnTypes: Array<string> = ['batchUpdate', 'delete'];
    this.operationOptions.forEach(x => {
      if (dynamicBtnTypes.includes(x.operationType)) {
        x.disabled = value.length === 0;
      }
    });
  }
}
